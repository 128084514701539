// + 1px
@mixin when-greater-than($point) {
  @if $point == desktop {
    @media (min-width: 1441px) {
      @content;
    }
  } @else if $point == tabletLandscape {
    @media (min-width: 993px) {
      @content;
    }
  } @else if $point == tabletPortrait {
    @media (min-width: 769px) {
      @content;
    }
  } @else if $point == phablet {
    @media (min-width: 577px) {
      @content;
    }
  } @else if $point == mobile {
    @media (min-width: 436px) {
      @content;
    }
  }
}

/* ----------------------------------- // ----------------------------------- */

@mixin when-less-than($point) {
  @if $point == desktop {
    @media (max-width: 1440px) {
      @content;
    }
  }

  @if $point == 1200 {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $point == tabletLandscape {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $point == tabletPortrait {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point == phablet {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: 435px) {
      @content;
    }
  }
}
