@use '@angular/material' as mat;

/* -------------------------------- CSS VARS -------------------------------- */

:root {
  --primary-color: #0a84ff;
  --primary-color-darker: #075cb3;
  --primary-color-lighter: #cee6ff;
  --accent-color: #ff6917;
  --accent-color-darker: #e65f15;
  --accent-color-lighter: #ff965d;

  --dark-grey: #24292e;
  --grey: #6a737d;
  --grey-40: #939eac;
  --grey-70: #e1e4e8;
  --grey-90: #f6f8fa;
  --negative: #ff3b30;
  --positive: #34c759;
  --amber: #FFC107;
  --amber-dark: #b88a00;
  --amber-light: #ffe9a8;
  --bg: #e0e0e0;
}

/* -------------------------------- SASS VARS ------------------------------- */

$primary: var(--primary-color);
$primary-darker: var(--primary-color-darker);
$primary-lighter: var(--primary-color-lighter);
$accent: var(--accent-color);
$accent-darker: var(--accent-color-darker);
$accent-lighter: var(--accent-color-lighter);

$dark-grey: var(--dark-grey);
$grey: var(--grey);
$grey-40: var(--grey-40);
$grey-70: var(--grey-70);
$grey-90: var(--grey-90);
$negative: var(--negative);
$positive: var(--positive);

/* ------------------------- DEFAULT COLOR PALETTES ------------------------- */

$default-primary-palette: (
  50: white,
  100: #c7e3ff,
  200: #8fc7ff,
  300: var(--primary-color-lighter),
  400: #2993ff,
  500: var(--primary-color),
  600: #0075ea,
  700: #0065cc,
  800: #0056ad,
  900: var(--primary-color-darker),
  A100: white,
  A200: #a3d1ff,
  A400: #3d9eff,
  A700: #2391ff,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$default-secondary-palette: (
  50: white,
  100: #ffe3d4,
  200: #ffbf9c,
  300: var(--accent-color-lighter),
  400: #ff7d36,
  500: var(--accent-color),
  600: #f75700,
  700: #d94d00,
  800: #ba4200,
  900: var(--accent-color-darker),
  A100: white,
  A200: #ffccb0,
  A400: #ff8a4a,
  A700: #ff7930,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

@function foregroundMap($color) {
  @return (
    base: $color,
    disabled: rgba($color, 0.38),
    disabled-button: rgba($color, 0.38),
    disabled-text: rgba($color, 0.38),
    hint-text: rgba($color, 0.38),
    secondary-text: rgba($color, 0.54),
    icon: rgba($color, 0.54),
    icons: rgba($color, 0.54),
    text: rgba($color, 0.87),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38),
    slider-min: rgba($color, 0.38)
  );
}

/* ------------------------------- THEME SETUP ------------------------------ */

$profile-primary: mat.define-palette($default-primary-palette);
$profile-accent: mat.define-palette($default-secondary-palette);
// $profile-warn: mat.define-palette(mat.$red-palette); // optional

$profile-typography: mat.define-typography-config(
  $font-family: 'Montserrat',
  $headline: mat.define-typography-level(16px, 16px, 600),
  $title: mat.define-typography-level(16px, 16px, 600),
);

$custom-theme: mat.define-light-theme(
  (
    color: (
      primary: $profile-primary,
      accent: $profile-accent,
      // warn: $profile-warn,
    ),
    typography: $profile-typography,
  )
);

$custom-foreground: foregroundMap(var(--dark-grey));

/* -------------------------------- SET THEME ------------------------------- */

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$profile-theme: map-merge(
  $custom-theme,
  (
    foreground: $custom-foreground,
  )
);

/* For the non-Angular Material items */
body {
  color: var(--dark-grey);
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($profile-theme);
