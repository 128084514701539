/* You can add global styles to this file, and also import other style files */

#angularAppRoot {
  opacity: 0;
}

#angularContent #angularAppRoot {
  opacity: 1;
}

html,
body {
  height: auto;
  font-family: 'Montserrat', 'Segoe UI', Tahoma, sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', 'Segoe UI', Tahoma, sans-serif !important;
  font-weight: 600;
  color: var(--dark-grey);
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
  margin: 1em 0;
}

html {
  overflow-y: auto !important;
}

body {
  margin: 0;
  font-size: 14px;
  // padding: 0;
}

a {
  text-decoration: none !important;
}

label {
  margin-bottom: 0 !important;
}

@import './styles/theme';
@import './styles/materialOverrides';
@import './styles/breakpoints';
@import './styles/global';
@import './styles/variables';
