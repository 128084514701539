$header-height: 56px;
$header-height-desktop: 80px;
$subheader-height: 56px;

$side-nav-width: 280px;
$max-panel-width: 580px;
$min-panel-width: 450px;
$scroll-width-padding: 10px;

$btp-red: #DE382F;
$btp-green: #1d9c3d;
