/* -------------------------------- MAT CARD -------------------------------- */

mat-card {
  border-radius: 8px !important;

  mat-card-title {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  mat-card-subtitle {
    font-size: 12px !important;
    margin-bottom: 8px !important;
  }

  mat-card-content {
    margin-bottom: 12px !important;
  }

  mat-card-footer {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    margin: auto !important;
  }
}

/* ----------------------------- MAT FORM FIELD ----------------------------- */

.mat-form-field {
  line-height: 1.5 !important;
  width: 100%;
}

.mat-form-field-wrapper {
  padding-bottom: 1em !important;
}

.no-error > .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-form-field-infix {
  padding: 6px 0 !important;
  display: flex !important;
  width: 100% !important;
  border-top: 10px solid transparent !important;
  min-height: 24px;
}

.mat-form-field-appearance-outline {
  .mat-form-field-wrapper {
    margin: 0 !important;
  }

  .mat-form-field-outline-start {
    border-radius: 8px 0 0 8px !important;
    width: 8px !important;
  }

  .mat-form-field-outline-end {
    border-radius: 0 8px 8px 0 !important;
  }
}

/* ------------------------------- MAT BUTTONS ------------------------------ */

.mat-flat-button {
  padding: 0 8px !important;
  min-width: auto !important;
  border-radius: 8px !important;
}

.mat-stroked-button {
  padding: 0 8px !important;
  min-width: auto !important;
  border-radius: 8px !important;
  border-color: transparent !important;
}

.mat-button-wrapper {
  > i {
    padding: 0 4px !important;
  }
}

.mat-button-disabled {
  color: rgba(0, 0, 0, 0.15) !important;
}

.mat-icon-button {
  // height: inherit !important;
  // width: inherit !important;
  line-height: inherit !important;
}

/* ------------------------------ MAT PAGINATOR ----------------------------- */

// changing icon > svg path
.mat-paginator-navigation-previous {
  color: var(--primary-color);

  .mat-paginator-icon > path {
    fill: currentColor;
    d: path('M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z');
  }
}

.mat-paginator-navigation-next {
  color: var(--primary-color);

  .mat-paginator-icon > path {
    fill: currentColor;
    d: path('M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z');
  }
}

/* ---------------------------- MAT BOTTOM SHEET ---------------------------- */

.mat-bottom-sheet-container {
  padding: 16px !important;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 0 15px 7px rgba(82, 59, 59, 0.15);
  height: fit-content;
  overflow-x: hidden !important;
}

/* -------------------------------- MAT MENU -------------------------------- */

.mat-menu-item {
  .language-active {
    color: var(--accent-color);
  }

  .language-inactive {
    color: black;
  }
}

/* -------------------- WRAPPER-CLASS TARGETTED OVERRIDES ------------------- */

.btp-toggle-group {
  .mat-button-toggle-label-content {
    line-height: 31px !important;
  }

  .mat-button-toggle-ripple {
    border-radius: 6px;
  }
}

.btp-paginator {
  .mat-paginator-container {
    min-height: 46px !important;
  }
}

profile-dropdown {
  .mat-checkbox {
    .mat-ripple-element {
      display: none !important;
    }

    .mat-checkbox-frame {
      border-color: var(--grey40);
    }
  }

  .mat-checkbox-checked .mat-checkbox-background {
    background-color: white !important;
    border: 2px solid var(--primary-color);

    .mat-checkbox-checkmark-path {
      stroke: var(--primary-color) !important;
      stroke-width: 3px;
    }
  }
}
