@import './variables';
// any classes that may be necessary globally, for consistant/DRY code,
// should be placed here, ideally with a "btp-" prefix. This lets developers
// know where such prefixed classes live.

/* --------------------------------- BUTTONS -------------------------------- */

[class*='btp-btn-'] {
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease-in-out !important;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.15)) top/100% 800%;
  min-width: auto !important;

  &:hover {
    background-position: bottom;
  }
}

.btp-btn {
  &-lg {
    border-radius: 20px !important;
    height: 40px;
    font-size: 16px !important;
    font-weight: 600 !important;
    width: -webkit-fill-available;

    span {
      display: flex;
    }
  }

  &-md {
    height: 36px;
    font-weight: 600 !important;

    &-txt {
      // transition: opacity 0.15s ease-in-out, background-color 0.15s ease-in-out !important;
      padding: 0 8px !important;
      background: transparent;

      &:hover {
        background-color: white !important;
      }
    }
  }

  &-sm {
    height: 36px;

    &-txt {
      // transition: opacity 0.15s ease-in-out, background-color 0.15s ease-in-out !important;
      min-width: 36px !important;
      background: transparent;

      &:hover {
        background-color: white !important;
      }
    }
  }

  &-circ {
    width: 30px;
    height: 30px;
    font-size: 24px;
    background-color: #dedede !important;
    border-radius: 50% !important;
    min-width: 30px !important;

    .mat-button-wrapper {
      padding: 0 !important;
    }

    &:hover {
      background-color: #ececec !important;
    }
  }

  &-tertiary {
    background-color: white !important;
    border: var(--primary-color) 2px solid !important;
    color: var(--primary-color) !important;
  }
}

/* --------------------------------- SHADOWS -------------------------------- */

.btp-shadow-none {
  box-shadow: none !important;
}

.btp-shadow-low {
  box-shadow: 0px 2px 8px rgba(36, 41, 46, 0.1) !important;
}

.btp-shadow-mid {
  box-shadow: 0px 4px 12px rgba(36, 41, 46, 0.1) !important;
}

.btp-shadow-high {
  box-shadow: 0px 6px 24px rgba(36, 41, 46, 0.1) !important;
}

// rgba(36, 41, 46, .15)

.btp-glow {
  box-shadow: 0 0 10px 10px white !important;
}

/* -------------------------------- SCROLLING ------------------------------- */

.btp-scroll {
  transition: color 500ms !important;
  color: #ddd !important;

  > * {
    color: initial !important;
  }

  &:hover {
    color: #bebebe !important;
  }
}

// this defaults all scrollbars in all elements, within BTP Profile (or cdk overlay)
profile-layout,
.cdk-overlay-container {
  div * {
    scrollbar-width: thin;
    scrollbar-color: #ccc transparent;

    ::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;
    }

    ::-webkit-scrollbar-track {
      background-color: #eee;
      margin: 8px;
      border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb {
      /* The box-shadow inherits the text color by default. */
      box-shadow: inset 0 0 0 20px;
      border-radius: 20px;
      margin: 8px;
      min-height: 100px;
      border: 3px solid #ccc;
    }
  }
}

/* ---------------------------------- MISC ---------------------------------- */

.btp-fill-flex {
  flex: 1;
}

.btp-mini-spinner {
  height: 24px !important;
  width: 24px !important;
  display: flex !important;
  margin-right: -24px;
  left: -36px;

  > svg {
    width: 90% !important;
    height: 90% !important;
    position: relative !important;
    margin: auto;

    > circle {
      stroke: inherit !important;
      stroke-width: inherit !important;
    }
  }
}

.btp-form-label {
  font-size: 12px;
  min-height: 16px;
  display: block;
  margin-bottom: 2px !important;
}

.btp-disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.btp-required-dot {
  color: $btp-red;
  font-size: 7px;
  margin-left: 4px;
}

.btp-bold-red {
  color: $btp-red;
  font-weight: 500;
}

.btp-bold-green {
  color: $btp-green;
  font-weight: 500;
}

/* ----------------------------------- // ----------------------------------- */

.job-search-filter-drawer {
  min-height: 500px;
}

@include when-greater-than(tabletPortrait) {
  .job-search-filter-drawer {
    max-width: 600px;
    height: 100vh;
    width: 60vw;
    position: fixed;
    bottom: 0;
    right: 0;
  }
}

.recruiter-details-drawer {
  min-height: 250px;
}

@include when-greater-than(tabletPortrait) {
  .recruiter-details-drawer {
    max-width: 600px;
    min-height: 100vh;
    width: 50vw;
    position: fixed;
    bottom: 0;
    right: 0;
    top: 56px;
  }
}

/* ------------------------------ BTP PAGINATOR ----------------------------- */

// .btp-pag-btn {
//   border: none;
//   border-radius: 7px;
//   color: var(--primary-color);
//   margin: 0 6px;
//   width: 24px !important;
//   height: 24px !important;
//   line-height: 12px !important;
//   background: transparent;
//   font-weight: 600;
// }

// .btp-pag-btn-disabled {
//   color: #cacaca;
// }

// mat-paginator[btppagination] {
//   background-color: transparent !important;
// }

/* ---------------------------- NGX INTL TEL INPUT ---------------------------- */
ngx-intl-tel-input {
  display: flex;
  width: 100%;

  .iti {
    width: 100%;

    .iti__flag-container {
      width: 90px;
    }

    .dropdown-toggle {
      background: var(--bg);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      height: 96%;
      margin-top: 0.5%;

      .selected-dial-code {
        margin-left: 10px;
      }
    }

    input {
      width: 100%;
      height: 42px;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      padding-left: 100px !important;

      &:hover {
        border-color: rgba(0, 0, 0, 0.87);
      }
    }
  }

  &.ng-invalid.ng-touched .iti input {
    border: 1px solid $btp-red;
  }
}

.dark-primary-text {
  color: var(--dark-grey);
}

.dark-secondary-text {
  color: var(--grey);
}
